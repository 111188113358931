#terminal, .xterm {
    position: fixed;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    margin:0;
    padding:5px;
}

#body, html {
    margin:0;
    padding:0;
}
